.title {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 1rem;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

/*#man {*/
/*    visibility: inherit;*/
/*}*/

.ring {
    position:absolute;
    z-index: 2;
}

.man2 {
    position:absolute;
    z-index: 2;
    width: 100px;
    height: 100px;
}